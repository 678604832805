



















































































import Vue from 'vue'
import Component from 'vue-class-component'
import api from '@/api'
import { FileAttachment, DataPoint } from '@/api/types'
import '@/styles/content.sass'
import { CurriculumResourceInfo, isAxiosError, request } from '@/edshed-common/api'

@Component({})
export default class ResourceView extends Vue {
  private resource: CurriculumResourceInfo | null = null
  private fileStats: DataPoint[] | null = null

  public mounted () {
    this.$nextTick(() => {
      this.loadResource()
    })
  }

  public getDownloadUrl (file: FileAttachment) {
    const baseUrl = `${api.getServerBaseUri()}resources/files/${file.id}`

    if (this.$store.state.token) {
      return `${baseUrl}?token=${this.$store.state.token}`
    } else {
      return baseUrl
    }
  }

  // public async download(file: FileAttachment) {
  //   const response = await fetch(
  //     `${api.getServerBaseUri()}resources/files/${file.id}`,
  //     {
  //       headers: new Headers({
  //         'X-Spellingshed-Token': this.$store.state.token
  //       })
  //     }
  //   )

  //   const blob = await response.blob()

  //   const blobUrl = window.URL.createObjectURL(
  //     new Blob([blob], { type: response.headers.get('content-type')! })
  //   )

  //   const link = document.createElement('a')
  //   link.href = blobUrl
  //   link.setAttribute('download', response.headers.get('x-filename')!)
  //   document.body.appendChild(link)
  //   link.click()
  // }

  private async showStats (file: FileAttachment) {
    const response = await fetch(
      `${api.getServerBaseUri()}resources/files/${file.id}/stats`,
      {
        headers: new Headers({
          'X-Spellingshed-Token': this.$store.state.token
        })
      }
    )

    this.fileStats = await response.json()
  }

  private async loadResource () {
    try {
      const response = await request(
        'GET',
        'resources/resources/' + this.$route.params.id,
        null
      )

      const data = response.data
      this.resource = data.resource

      document.title = 'EdShed Resource // ' + this.resource!.title
      document
        .querySelector('meta[name="description"]')!
        .setAttribute('content', this.resource!.description)
    } catch (error: unknown) {
      console.log(error)

      if (isAxiosError(error) && error.response?.status === 403) {
        console.log('FORBIDDEN')
        this.$router.push('/logout')
      }
      // alert('Details incorrect')
    }
  }
}
